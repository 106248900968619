import axios from "axios"
import { env } from '../constants'


/* Characteristic */
export const list = () => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}characteristic/list`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* CREATE */
export const createCharacteristic = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}characteristic/create`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}
