import React, { Component } from "react"


/*
    Page Roles
*/
class Roles extends Component {  

    render = () => {

        return (
            <div>
                Roles
            </div>
        )

    }

}


export default Roles