import React, { Component } from "react"

/* JWT */
import { decodeToken } from "react-jwt"



/*
    Page Dashboard
*/
class Dashboard extends Component {


    /* Draw Top */
    _top = () => {

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/dashboard.png" alt="Dashboard" />
                    <p>Dashboard</p>
                </div>

            </div>
        )
    }


    render = () => {

        const token = localStorage.getItem("token")
        const data = decodeToken(token)

        return (
            <div className="content">
                {this._top()}
                <div className="dashboard-welcome">
                    <h2>Здравствуйте, <b>{data.name}</b></h2>
                    <p>Новых заказов нет</p>
                </div>
            </div>
        )
    }

}

export default Dashboard