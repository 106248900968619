
/* Order status fields */
const NEW = 'new'
const PENDING = 'pending'
const ONTHEWAY = 'ontheway'
const COMPLETED = 'completed'
const CANCELED = 'canceled'

const PICKUP = 'pickup'
const DELIVERY = 'delivery'

const CASH = "cash"
const CARD = "card"

const PAID = "paid"
const NOTPAID = "notpaid"





/* PRICE CONVERTOR */
const convertor = price => {

    /* Check the price for the void */
    if (price === null || price === undefined)
        return ""

    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ₸"
}


/* CHECK DATA */
const check = data => {
    if (data && data !== undefined && data !== null) {
        return data
    }

    return "-"
}


/* ORDER DELIVERY STATUS */
const deliveryStatus = (delivery, status) => {

    if (status === NEW) {
        return <div className="tag green">Заказ создан</div>
    }

    if (status === PENDING) {
        return <div className="tag yellow">В обработке</div>
    }

    if (status === ONTHEWAY) {
        return <div className="tag whiteblue">В пути</div>
    }

    if (status === COMPLETED) {
        if (delivery === PICKUP) {
            return <div className="tag darkgreen">Завершен</div>
        }
        if (delivery === DELIVERY) {
            return <div className="tag blue">Доставлен</div>
        }
    }

    if (status === CANCELED) {
        return <div className="tag red">Отменен</div>
    }

}



/* ORDER DELIVERY TYPE */
const deliveryType = delivery => {
    if (delivery === PICKUP) {
        return <div className="tag purple">Самовывоз</div>
    }
    if (delivery === DELIVERY) {
        return <div className="tag darkblue">Доставка</div>
    }
}


/* ORDER PAYMENT TYPE */
const paymentType = payment => {
    if (payment === CASH) {
        return <div className="tag pink">Наличные</div>
    }
    if (payment === CARD) {
        return <div className="tag darkblue">С картой</div>
    }
}


/* ORDER PAYMENT STATUS */
const paymentStatus = status => {
    if (status === PAID) {
        return <div className="tag green">Оплачен</div>
    }
    if (status === NOTPAID) {
        return <div className="tag red">Не оплачен</div>
    }
}


/* ORDER STATUS LIST */
const DELIVERY_STATUS = [
    { key: NEW, text: 'Новый заказ', color: 'green' },
    { key: PENDING, text: 'В обработке', color: 'yellow' },
    { key: ONTHEWAY, text: 'В пути', color: 'whiteblue' },
    { key: COMPLETED, text: 'Доставлен', color: 'blue' },
    { key: CANCELED, text: 'Отменен', color: 'red' },
]

const PICKUP_STATUS = [
    { key: NEW, text: 'Новый заказ', color: 'green' },
    { key: PENDING, text: 'В обработке', color: 'yellow' },
    { key: COMPLETED, text: 'Завершен', color: 'darkgreen' },
    { key: CANCELED, text: 'Отменен', color: 'red' },
]


/* DELVERY TYPE LIST */
const DELIVERY_TYPE = [
    { key: PICKUP, text: 'Самовывоз', color: 'purple' },
    { key: DELIVERY, text: 'Доставка', color: 'darkblue' },
]

/* PAYMENT TYPE LIST */
const PAYMENT_TYPE = [
    { key: CASH, text: 'Наличные', color: 'pink' },
    { key: CARD, text: 'С картой', color: 'darkblue' },
]

/* PAYMENT STATUS LIST */
const PAYMENT_STATUS = [
    { key: PAID, text: 'Оплачен', color: 'green' },
    { key: NOTPAID, text: 'Не оплачен', color: 'red' },
]


/* TRANSLIT */
const translit = word => {

    let text = ''

    const converter = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i',
        'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
        'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
        'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch',
        'ш': 'sh', 'щ': 'sch', 'ь': '', 'ы': 'y', 'ъ': '',
        'э': 'e', 'ю': 'yu', 'я': 'ya',

        'А': 'a', 'Б': 'b', 'В': 'b', 'Г': 'g', 'Д': 'd',
        'Е': 'e', 'Ё': 'e', 'Ж': 'zh', 'З': 'z', 'И': 'i',
        'Й': 'y', 'К': 'k', 'Л': 'l', 'М': 'm', 'Н': 'n',
        'О': 'o', 'П': 'p', 'Р': 'r', 'С': 's', 'Т': 't',
        'У': 'u', 'Ф': 'f', 'Х': 'H', 'Ц': 'C', 'Ч': 'ch',
        'Ш': 'sh', 'Щ': 'sch', 'Ь': '', 'Ы': 'y', 'Ъ': '',
        'Э': 'e', 'Ю': 'yu', 'Я': 'ya'
    }


    for (let i = 0; i < word.length; ++i) {
        if (word[i] === " ") {
            text += "-"
        }
        else if (converter[word[i]] === undefined || converter[word[i]] === null) {
            text += word[i]
        }
        else {
            text += converter[word[i]]
        }
    }

    return text

}


/* PHONE FORMATTER */
const formatter = (phone, mask) => {

    if (phone === null || phone === undefined || phone === "") {
        return "-"
    }

    if (!mask) return phone

    const numeric = phone.replaceAll(/[^\d]/g, '')

    let idx = 0

    const formated = mask.split('').map(el => {

        if (el === '#') {
            el = numeric[idx]
            idx++
        }

        return el
    })

    return formated.join('')
}


/* GENDER */
const gender = gender => {

    if (gender === undefined || gender === null) {
        return "-"
    }

    if (parseInt(gender) === 1) {
        return "Мужской"
    }
    if (parseInt(gender) === 2) {
        return "Женский"
    }

    return "-"
}



/*
    Check Number
*/
const isNumeric = value => {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(value)
}


export { formatter, check, deliveryStatus, deliveryType, paymentType, paymentStatus, convertor, DELIVERY_STATUS, PICKUP_STATUS, DELIVERY_TYPE, PAYMENT_TYPE, PAYMENT_STATUS, translit, gender, isNumeric }