import React, { Component } from "react"

/* Router */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

/* Pages */
import { Login, Register, NotFound } from './Auth'


/*
    Layout Authorization
*/
class Authorization extends Component {

    render = () => {

        const { setToken } = this.props

        return (
            <Router>
                <Routes>
                    <Route path="*" element={<NotFound />} />
                    <Route path="/" element={<Login setToken={setToken} />} />
                    <Route path="/register" element={<Register />} />
                </Routes>
            </Router>
        )
    }

}

export default Authorization