import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { list, count } from '../../api/Order'

/* Pagination */
import { Pagination } from '../../components'

/* Helpers */
import { utils, withRouter } from '../../helpers'

/* Router */
import { Link } from "react-router-dom"




/* 
    Page Orders 
*/
class Orders extends Component {

    constructor() {
        super()

        this.state = {
            loading: true,
            data: [],
            page: 1,
            search: "",
            count: 0,
        }
    }


    componentDidMount = () => {
        this.load()
        this.count()
    }

    /* Load */
    load = (page = 1, text = '') => {

        this.setState({ page, loading: true })

        list({ page, search: text }).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data, loading: false })
            }
        })
    }

    /* Count */
    count = () => {
        count().then(response => {
            if (response.status === 200) {
                this.setState({ count: response.data.total })
            }
        })
    }

    /* Search */
    search = text => {
        this.setState({ search: text })

        if (text !== "") {
            this.setState({ loading: true })

            if (this._timeout) {
                clearTimeout(this._timeout)
            }

            this._timeout = setTimeout(() => {
                this.load(1, text.trim())
            }, 1000)
        }
    }



    /* Draw Top */
    _top = () => {

        const { count, search } = this.state

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/orders.png" alt="Order" />
                    <p>Заказы</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

                <div className="content-search">
                    <img src="/pictures/search.png" alt="Search" />
                    <input value={search} onChange={event => this.search(event.target.value)} placeholder="Поиск" />
                </div>

            </div>
        )
    }



    /* Draw Content */
    _content = () => {

        const { data, loading, page } = this.state

        /* DRAW LOADER */
        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        /* DRAW EMPTY */
        if (data.length === 0) {
            return (
                <div className="content-element">
                    По вашему запросу ничего не найдено
                </div>
            )
        }

        return (
            <div className="content-data-box order">
                {data.map((item, index) =>
                    <div className="content-box-body" key={`${index}`}>
                        <div className="content-item number">{index + 1 + (page - 1) * 10}</div>
                        <Link to={`/order/${item.id}`} className="content-item name blue">#{utils.check(item.number)}</Link>
                        <div className="content-item">{utils.check(item.name)}</div>
                        <div className="content-item">{utils.deliveryType(item.deliveryType)}</div>
                        <div className="content-item">{utils.deliveryStatus(item.deliveryType, item.deliveryStatus)}</div>
                        <div className="content-item content-buttons">
                            <Link to={`/order/${item.id}`} className="content-item-button">Посмотреть</Link>
                        </div>
                    </div>
                )}
            </div>
        )
    }


    render = () => {

        const { count, page, search } = this.state

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">

                    <div className="content-box-head order">
                        <div className="content-head number">#</div>
                        <div className="content-head name">Номер заказа</div>
                        <div className="content-head">Пользователь</div>
                        <div className="content-head">Статус доставки</div>
                        <div className="content-head">Статус заказа</div>
                        <div className="content-head"></div>
                    </div>

                    {this._content()}

                    <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page, search)} />

                </div>

            </div>
        )
    }

}

export default withRouter(Orders)