import React, { Component } from "react"

/* Icons */
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'



/* Fields */
const DOTS = '...'

/* Helpers */
const range = (start, end) => {
    let length = end - start + 1
    return Array.from({ length }, (_, idx) => idx + start)
}




/*
    Component Pagination
*/
class Pagination extends Component {


    generate = () => {

        const { total, size, page } = this.props
        const sibling = 2

        const totalPageCount = Math.ceil(total / size)
        const totalPageNumbers = sibling + 5

        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount)
        }

        const leftSiblingIndex = Math.max(page - sibling, 1)
        const rightSiblingIndex = Math.min(page + sibling, totalPageCount)

        const shouldShowLeftDots = leftSiblingIndex > 2
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

        const firstPageIndex = 1
        const lastPageIndex = totalPageCount


        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * sibling
            let leftRange = range(1, leftItemCount)

            return [...leftRange, DOTS, totalPageCount]
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * sibling
            let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount)

            return [firstPageIndex, DOTS, ...rightRange]
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex)
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
        }


        return []
    }


    prev = () => {
        const { page, onPageChange } = this.props
        onPageChange(page - 1)
    }

    next = () => {
        const { page, onPageChange } = this.props
        onPageChange(page + 1)
    }


    /* Draw left button */
    _left = () => {

        const { page } = this.props

        if (parseInt(page) !== 1) {
            return (
                <div onClick={() => this.prev()} className="pagination-left-arrow pagination-item">
                    <CaretLeftOutlined />
                </div>
            )
        }
        else {
            return (
                <div className="pagination-left-arrow pagination-item pagination-disabled">
                    <CaretLeftOutlined />
                </div>
            )
        }
    }

    /* Draw right button */
    _right = last => {

        const { page } = this.props

        if (parseInt(page) !== parseInt(last)) {
            return (
                <div onClick={() => this.next()} className="pagination-right-arrow pagination-item">
                    <CaretRightOutlined />
                </div>
            )
        }
        else {
            return (
                <div className="pagination-right-arrow pagination-item pagination-disabled">
                    <CaretRightOutlined />
                </div>
            )
        }
    }


    render = () => {

        const { onPageChange, total, size, page } = this.props
        const pages = this.generate()

        if (parseInt(total) > 0 && parseInt(total) > parseInt(size)) {

            const last = pages[pages.length - 1]

            return (
                <div className="pagination">

                    {this._left()}

                    {pages.map((number, index) => {
                        if (number === DOTS) {
                            return <div key={`${index}`} className="pagination-dots">{DOTS}</div>
                        }

                        return <div onClick={() => onPageChange(number)} className={parseInt(page) === parseInt(number) ? "pagination-item pagination-active" : "pagination-item"} key={`${index}`}>{number}</div>
                    })}

                    {this._right(last)}

                </div>
            )
        }

        return <div />
    }

}

export default Pagination