import axios from "axios"
import { env } from '../constants'


/* Product */
export const list = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}review/list`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Count */
export const count = () => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}review/count`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* Remove */
export const remove = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}review/remove`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}