import axios from "axios"
import { env } from '../constants'


/* ORDERS */
export const list = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/list`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* COUNT */
export const count = () => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/count`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* VIEW */
export const view = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/view`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* UPDATE */
export const changeDeliveryStatus = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/deliveryStatus`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* UPDATE */
export const changeDeliveryType = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/deliveryType`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* UPDATE */
export const changePaymentType = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/paymentType`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* UPDATE */
export const changePaymentStatus = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/paymentStatus`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}


/* UPDATE */
export const setStore = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/setStore`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* STORES */
export const stores = () => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/stores`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}