import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { list, count, remove } from '../../api/Review'

/* Pagination */
import { Pagination } from '../../components'

/* SweetAlert */
import Swal from "sweetalert2"

/* SweetAlert */
import { utils } from "../../helpers"

/* Moment */
import moment from "moment"
import 'moment/locale/ru'
moment.locale('ru')


/* Page Reviews */
class Reviews extends Component {

    constructor() {
        super()

        this.state = {
            loading: true,
            data: [],
            page: 1,
            count: 0,
        }
    }


    componentDidMount = () => {
        this.load()
        this.count()
    }

    /* Load */
    load = (page = 1) => {

        this.setState({ page, loading: true })

        list({ page }).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data, loading: false })
            }
        })

    }

    /* Count */
    count = () => {
        count().then(response => {
            if (response.status === 200) {
                this.setState({ count: response.data.total })
            }
        })
    }



    /* Draw Top */
    _top = () => {

        const { count } = this.state

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/review.png" alt="Review" />
                    <p>Отзывы</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

            </div>
        )
    }


    /* Remove */
    remove = async (item) => {

        Swal.fire({
            title: 'Вы действительно хотите удалить?',
            text: "Невозможно восстановить после удаления",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f44336',
            confirmButtonText: 'Да, удалить!',
            cancelButtonText: 'Нет'
        }).then((result) => {
            if (result.isConfirmed) {
                remove({ id: item.id }).then(response => {
                    if (response.status === 200) {

                        const { data } = this.state
                        const index = data.findIndex(e => parseInt(e.id) === parseInt(item.id))
                        if (index > -1) {
                            data.splice(index, 1)
                            this.setState({ data })
                        }

                        Swal.fire({
                            icon: 'success',
                            title: 'Успешно удалено',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Что-то пошло не так! Попробуйте позднее',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }).catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Что-то пошло не так! Попробуйте позднее',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
            }
        })
    }



    /* Draw Content */
    _content = () => {

        const { data, loading, page } = this.state

        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="content-element">
                    Здесь пока пусто
                </div>
            )
        }

        return (
            <div className="content-data-box">
                {data.map((item, index) =>
                    <div className="content-box-body" key={`${index}`}>
                        <div className="content-item number">{index + 1 + (page - 1) * 10}</div>
                        <div className="content-item">{utils.check(item.username)}</div>
                        <div className="content-item name">{utils.check(item.product)}</div>
                        <div className="content-item name">
                            <div className="content-item-review">
                                <p>
                                    <b>Оценка: </b>
                                    <div className="review-rate">
                                        {utils.check(item.rate)}
                                        {[1, 2, 3, 4, 5].map(star => {
                                            if (parseInt(item.rate) >= star) {
                                                return <img key={`${star}`} src="/pictures/star.png" alt="Star" />
                                            }
                                            else {
                                                return <div />
                                            }
                                        })}
                                    </div>
                                </p>
                                <p><b>Комментарии: </b>{utils.check(item.comment)}</p>
                                <p><b>Достоинство: </b>{utils.check(item.advantages)}</p>
                                <p><b>Недостаток: </b>{utils.check(item.flaw)}</p>
                                <p><b>Дата: </b>{item.createdAt ? moment(item.createdAt).format("Do MMMM YYYY, hh:mm") : "-"}</p>
                            </div>
                        </div>
                        <div className="content-item content-buttons number">
                            <div className="content-item-button delete" onClick={() => this.remove(item)}>Удалить</div>
                        </div>
                    </div>
                )}
            </div>
        )
    }


    render = () => {

        const { count, page } = this.state

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">

                    <div className="content-box-head">
                        <div className="content-head number">#</div>
                        <div className="content-head">Пользователь</div>
                        <div className="content-head name">Продукт</div>
                        <div className="content-head name">Отзыв</div>
                        <div className="content-head number"></div>
                    </div>

                    {this._content()}

                    <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page)} />

                </div>

            </div>
        )
    }

}

export default Reviews