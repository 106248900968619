import axios from "axios"
import { env } from '../constants'


/* Product */
export const list = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}product/list`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Count */
export const count = () => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}product/count`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* Update */
export const update = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}product/edit`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Create */
export const create = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}product/create`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Remove */
export const remove = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}product/remove`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Status */
export const status = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}product/status`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* View */
export const view = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}product/view`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Remove Image */
export const removeImage = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}product/remove/image`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Upload Image */
export const uploadImage = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}product/upload/image`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Upload Gallery */
export const uploadGallery = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}product/upload/gallery`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Remove Gallery */
export const removeGallery = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}product/remove/gallery`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}



/* Create Characteristic */
export const addCharacteristic = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}product/create/characteristic`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Remove Characteristic */
export const removeCharacteristic = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}product/remove/characteristic`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}