import React, { Component } from "react"

/* Router */
import { Link } from "react-router-dom"

/*
    NotFound Page
*/
class NotFound extends Component {

    render = () => {
        return (
            <div className="not-found">
                <div className="not-found-title">Страница</div>
                <div className="not-found-big">404</div>
                <div className="not-found-title">не найдена</div>
                <Link to='/'>Вернуться на главную</Link>
            </div>
        )
    }

}

export default NotFound