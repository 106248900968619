import React, { Component } from "react"

/* REST API */
import { cities, get, update } from '../../api/Shop'

/* Sweet alert */
import Swal from "sweetalert2"

/* Helpers */
import { withRouter } from '../../helpers'




/*
    Page Edit Shop
*/
class EditShop extends Component {


    constructor(props) {
        super(props)

        const { params } = this.props

        this.state = {
            id: params.id,
            cities: [],

            name: "",
            address: "",
            cityID: 0,
            lat: "",
            lng: ""
        }
    }


    componentDidMount = () => {
        this.load()
        this.get()
    }


    /* Load Cities */
    load = () => {
        cities().then(response => {
            if (response.status === 200) {
                this.setState({ cities: response.data })
            }
        })
    }


    /* Load Category */
    get = () => {
        const { id } = this.state
        get({ id }).then(response => {
            if (response.status === 200) {
                const data = response.data
                this.setState({
                    name: data.name ? data.name : "",
                    address: data.address ? data.address : "",
                    cityID: data.cityID ? data.cityID : 0,
                    lat: data.lat ? data.lat : "",
                    lng: data.lng ? data.lng : ""
                })
            }
        })
    }


    /* Create */
    create = () => {

        const { id, name, address, lat, lng, cityID } = this.state
        const { navigate } = this.props

        if (name === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите название', showConfirmButton: false, timer: 1500 })
            return
        }

        if (address === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите Адрес', showConfirmButton: false, timer: 1500 })
            return
        }

        if (cityID === 0) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Выберите город', showConfirmButton: false, timer: 1500 })
            return
        }

        const data = { id, name, address, lat, lng, cityID }

        update(data).then(response => {
            if (response.status === 200) {

                Swal.fire({
                    icon: 'success',
                    title: 'Успешно сохранен',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/shop')
                    }
                })

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })

    }


    /* Draw Top */
    _top = () => {

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/store.png" alt="Store" />
                    <p>Редактировать магазин</p>
                </div>

            </div>
        )
    }


    render = () => {

        const { name, address, cityID, cities, lat, lng } = this.state

        return (
            <div className="content">
                
                {this._top()}

                <div className="content-box">

                    <div className="content-form-box">

                        <div className="content-form-half">

                            <p>Название магазина</p>
                            <input type="text" value={name} onChange={event => this.setState({ name: event.target.value })} placeholder="Введите название" />

                            <p>Адрес магазина</p>
                            <input type="text" value={address} onChange={event => this.setState({ address: event.target.value })} placeholder="Введите Адрес" />


                            <p>Город</p>
                            <select onChange={event => this.setState({ cityID: event.target.value })}>
                                <option seleted="true" value={0}>Не выбран</option>
                                {cities.map((item, index) =>
                                    <option selected={parseInt(cityID) === parseInt(item.id)} key={`${index}`} value={item.id}>{item.name}</option>
                                )}
                            </select>

                        </div>

                        <div className="content-form-half">
                            <h2>Координаты</h2>

                            <p>LAT</p>
                            <input type="text" value={lat} onChange={event => this.setState({ lat: event.target.value })} placeholder="Введите LAT" />

                            <p>LNG</p>
                            <input type="text" value={lng} onChange={event => this.setState({ lng: event.target.value })} placeholder="Введите LNG" />

                        </div>

                    </div>

                    <div className="content-form-bottom">
                        <div onClick={() => this.create()} className="content-form-button">Сохранить</div>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(EditShop)