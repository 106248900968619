import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { list, count, remove } from '../../api/Shop'

/* Pagination */
import { Pagination } from '../../components'

/* Helpers */
import { utils } from '../../helpers'

/* SweetAlert */
import Swal from "sweetalert2"

/* React Router */
import { Link } from "react-router-dom"



/* Page Shops */
class Shops extends Component {

    constructor() {
        super()

        this.state = {
            loading: true,
            data: [],
            page: 1,
            count: 0,
        }
    }


    componentDidMount = () => {
        this.load()
        this.count()
    }

    /* Load */
    load = (page = 1) => {

        this.setState({ page, loading: true })

        list({ page }).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data, loading: false })
            }
        })

    }

    /* Count */
    count = () => {
        count().then(response => {
            if (response.status === 200) {
                this.setState({ count: response.data.total })
            }
        })
    }



    /* Draw Top */
    _top = () => {

        const { count } = this.state

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/store.png" alt="Store" />
                    <p>Магазины</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

                <div className="content-create">
                    <Link className="content-create-button" to="/shop/create">Добавить</Link>
                </div>

                <div className="content-create">
                </div>

            </div>
        )
    }


    /* Remove */
    remove = async (item) => {

        Swal.fire({
            title: 'Вы действительно хотите удалить?',
            text: "Невозможно восстановить после удаления",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f44336',
            confirmButtonText: 'Да, удалить!',
            cancelButtonText: 'Нет'
        }).then((result) => {
            if (result.isConfirmed) {
                remove({ id: item.id }).then(response => {
                    if (response.status === 200) {

                        const { data } = this.state

                        const index = data.findIndex(e => parseInt(e.id) === parseInt(item.id))

                        if (index > -1) {
                            data.splice(index, 1)
                            this.setState({ data })
                        }

                        Swal.fire({
                            icon: 'success',
                            title: 'Успешна удалена',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Что-то пошло не так! Попробуйте позднее',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }).catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Что-то пошло не так! Попробуйте позднее',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
            }
        })
    }


    /* Draw Content */
    _content = () => {

        const { data, loading, page } = this.state

        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="content-element">
                    Здесь пока пусто
                </div>
            )
        }

        return (
            <div className="content-data-box">
                {data.map((item, index) =>
                    <div className="content-box-body" key={`${index}`}>
                        <div className="content-item number">{index + 1 + (page - 1) * 10}</div>
                        <div className="content-item">{utils.check(item.name)}</div>
                        <div className="content-item">{utils.check(item.address)}</div>
                        <div className="content-item content-buttons">
                            <Link className="content-item-button green" to={`/shop/stock/${item.id}`}>Посмотреть запасы</Link>
                        </div>
                        <div className="content-item content-buttons name">
                            <Link className="content-item-button" to={`/shop/edit/${item.id}`}>Редактировать</Link>
                            <div className="content-item-button delete" onClick={() => this.remove(item)}>Удалить</div>
                        </div>
                    </div>
                )}
            </div>
        )
    }


    render = () => {

        const { count, page } = this.state

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">

                    <div className="content-box-head">
                        <div className="content-head number">#</div>
                        <div className="content-head">Название</div>
                        <div className="content-head">Адрес</div>
                        <div className="content-head"></div>
                        <div className="content-head name"></div>
                    </div>

                    {this._content()}

                    <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page)} />

                </div>

            </div>
        )
    }

}

export default Shops