import React, { Component } from "react"

/* Router */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

/* Pages */
import { Dashboard } from '../pages/Dashboard'
import { Managers } from '../pages/Manager'
import { Roles } from '../pages/Role'
import { Cities } from '../pages/City'
import { Users } from '../pages/User'
import { Reviews } from '../pages/Review'
import { Products, CreateProduct, EditProduct } from '../pages/Product'
import { Categories, CreateCategory, EditCategory } from '../pages/Category'
import { Orders, Order } from '../pages/Order'
import { Shops, CreateShop, EditShop, EditStock } from '../pages/Shop'
import { NotFound } from '../pages/Auth'


/* Widgets */
import Menu from './Menu'

/* JWT */
import { decodeToken } from "react-jwt"



/*
    Layout Dashboard
*/
class Layout extends Component {

    render = () => {


        const token = localStorage.getItem("token")
        const data = decodeToken(token)

        return (
            <Router>
                <div className="layout">

                    <Menu />

                    <div className="container">

                        <div className="header">

                            <div className="header-notification">
                                <img src="/pictures/bell.png" alt="Bell" />
                            </div>

                            <div className="header-user">

                                <img src="/pictures/profile.png" alt="Profile" />

                                <div className="header-name">
                                    <p>{data.name}</p>
                                    <span>{data.email}</span>
                                </div>

                            </div>

                        </div>

                        <Routes>
                            <Route path="*" element={<NotFound />} />
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/roles" element={<Roles />} />
                            <Route path="/city" element={<Cities />} />
                            <Route path="/user" element={<Users />} />
                            <Route path="/review" element={<Reviews />} />

                            <Route path="/product" element={<Products />} />
                            <Route path="/product/create" element={<CreateProduct />} />
                            <Route path="/product/edit/:id" element={<EditProduct />} />

                            <Route path="/category" element={<Categories />} />
                            <Route path="/category/create" element={<CreateCategory />} />
                            <Route path="/category/edit/:id" element={<EditCategory />} />

                            <Route path="/order" element={<Orders />} />
                            <Route path="/order/:id" element={<Order />} />

                            <Route path="/shop" element={<Shops />} />
                            <Route path="/shop/create" element={<CreateShop />} />
                            <Route path="/shop/edit/:id" element={<EditShop />} />
                            <Route path="/shop/stock/:id" element={<EditStock />} />

                            <Route path="/managers" element={<Managers />} />


                        </Routes>

                    </div>

                </div>


            </Router>
        )
    }
}

export default Layout