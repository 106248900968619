import axios from "axios"
import { env } from '../constants'


/* CATEGORIES */
export const list = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}category/list`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* COUNT */
export const count = () => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}category/count`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* REMOVE */
export const remove = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}category/remove`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* PARENT */
export const parent = () => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}category/parent`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* CREATE */
export const create = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}category/create`
    const headers = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* GET */
export const get = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}category/get`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* EDIT */
export const edit = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}category/edit`
    const headers = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* CHANGE STATUS */
export const status = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}category/status`
    const headers = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}
