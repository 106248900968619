import React, { Component } from "react"

/* Icons */
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons'

/* Helpers */
import { withRouter, utils } from '../../helpers'

/* REST API */
import { view, changeDeliveryStatus, changeDeliveryType, changePaymentStatus, changePaymentType, stores, setStore } from '../../api/Order'

/* SweetAlert */
import Swal from "sweetalert2"



/*
    Page Order
*/
class Order extends Component {


    constructor(props) {
        super(props)

        const { params } = this.props

        this.state = {
            id: params.id,
            data: null,
            loading: true,
            store: []
        }
    }

    componentDidMount = () => {
        this.load()
        this.stores()
    }

    /* Load Order Data */
    load = () => {
        const { id } = this.state

        view({ id }).then(response => {
            if (response.status === 200) {
                console.log(response.data)
                this.setState({ loading: false, data: response.data })
            }
        })
    }

    /* Load stores */
    stores = () => {
        stores().then(response => {
            if (response.status === 200) {
                this.setState({ store: response.data })
            }
        })
    }

    /* Draw Top */
    _top = () => {

        const { data } = this.state

        return (
            <div className="content-top">
                <div className="content-name">
                    <img src="/pictures/orders.png" alt="Order" />
                    <p>Заказ {data ? `#${utils.check(data.number)}` : ''}</p>
                </div>
            </div>
        )
    }

    /* Draw Content */
    _content = () => {

        const { data, loading } = this.state

        /* DRAW LOADER */
        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        /* DRAW EMPTY */
        if (data === null) {
            return (
                <div className="content-element">
                    Заказ не найден
                </div>
            )
        }

        let total = 0
        data.products.forEach(item => {
            total += parseInt(item.price) * parseInt(item.amount)
        })


        return (
            <div className="order-view">
                <div className="order-view-info">
                    <h2>Информация о заказе</h2>
                    <div className="order-row">
                        <div className="order-text">Номер заказа: </div>
                        <div className="order-value">#{utils.check(data.number)}</div>
                    </div>
                    <div className="order-row">
                        <div className="order-text">Клиент: </div>
                        <div className="order-value">{utils.check(data.name)}</div>
                    </div>
                    <div className="order-row">
                        <div className="order-text">Email: </div>
                        <div className="order-value">{utils.check(data.email)}</div>
                    </div>
                    <div className="order-row">
                        <div className="order-text">Телефон: </div>
                        <div className="order-value">{utils.check(data.phone)}</div>
                    </div>

                    <div className="order-row">
                        <div className="order-text">Статус доставки: </div>
                        <div className="order-value">{utils.deliveryType(data.deliveryType)}</div>
                    </div>

                    <div className="order-row">
                        <div className="order-text">Статус заказа: </div>
                        <div className="order-value">{utils.deliveryStatus(data.deliveryType, data.deliveryStatus)}</div>
                    </div>

                    <div className="order-row">
                        <div className="order-text">Оплата: </div>
                        <div className="order-value">{utils.paymentType(data.paymentType)}</div>
                    </div>

                    <div className="order-row">
                        <div className="order-text">Статус оплаты: </div>
                        <div className="order-value">{utils.paymentStatus(data.paymentStatus)}</div>
                    </div>

                </div>
                <div className="order-view-products">
                    <h2>Продукты</h2>
                    {data.products.map((item, index) =>
                        <div className="order-product" key={`${index}`}>
                            <div className="order-product-name">{item.product}</div>
                            <div className="order-product-amont-price">
                                <div className="order-product-bold">{item.amount}</div>
                                <div className="order-product-text">x</div>
                                <div className="order-product-bold">{utils.convertor(item.price)}</div>
                                <div className="order-product-text">=</div>
                                <div className="order-product-bold">{utils.convertor(item.price * item.amount)}</div>
                            </div>
                        </div>
                    )}
                    <div className="order-product-total">
                        Итого: <b>{utils.convertor(total)}</b>
                    </div>
                </div>
            </div>
        )
    }


    /* Change Delivery Status */
    changeDeliveryStatus = status => {

        const { id, data } = this.state

        changeDeliveryStatus({ id, status }).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Статус заказа успешно изменен',
                    showConfirmButton: false,
                    timer: 1500
                })

                data.deliveryStatus = status
                this.setState({ data })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }



    /* Change Delivery Type */
    changeDeliveryType = status => {

        const { id, data } = this.state

        changeDeliveryType({ id, status }).then(response => {
            if (response.status === 200) {

                Swal.fire({
                    icon: 'success',
                    title: 'Статус доставки успешно изменен',
                    showConfirmButton: false,
                    timer: 1500
                })

                if (status === 'pickup' && data.deliveryStatus === 'ontheway') {
                    this.changeDeliveryStatus('pending')
                }

                data.deliveryType = status
                this.setState({ data })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }




    /* Change Payment Type */
    changePaymentType = status => {

        const { id, data } = this.state

        changePaymentType({ id, status }).then(response => {
            if (response.status === 200) {

                Swal.fire({
                    icon: 'success',
                    title: 'Вид оплаты успешно изменен',
                    showConfirmButton: false,
                    timer: 1500
                })

                data.paymentType = status
                this.setState({ data })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }




    /* Change Payment Type */
    changePaymentStatus = status => {

        const { id, data } = this.state

        changePaymentStatus({ id, status }).then(response => {
            if (response.status === 200) {

                Swal.fire({
                    icon: 'success',
                    title: 'Вид оплаты успешно изменен',
                    showConfirmButton: false,
                    timer: 1500
                })

                data.paymentStatus = status
                this.setState({ data })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }


    /* Set Store */
    setStore = item => {

        const { id, data } = this.state

        console.log({ id, storeID: item.id })

        setStore({ id, storeID: item.id }).then(response => {
            if (response.status === 200) {

                data.storeID = item.id
                data.address = item.address
                data.store = item.name

                this.setState({ data })

                Swal.fire({
                    icon: 'success',
                    title: 'Магазин успешно выбран',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }


    /* Draw Status Changers */
    _change = () => {

        const { data, store } = this.state

        if (data) {

            const list = data.deliveryType === "pickup" ? utils.PICKUP_STATUS : utils.DELIVERY_STATUS

            return (
                <div className="order-status-box">

                    <div className="order-store-change">
                        <h2>Выберите магазин</h2>

                        {data.storeID === null
                            ? <div className="order-store-empty">Магазин не выбран</div>
                            : <div className="order-store-selected">
                                <div className="order-store-selected-name">{data.store}</div>
                                <div className="order-store-selected-address">{data.address}</div>
                            </div>
                        }

                        <div className="order-stores-list">
                            {store.map((item, index) =>
                                <div onClick={() => this.setStore(item)} key={`${index}`} className="order-store-item">
                                    <img src="/pictures/store-white.png" alt="Store" />
                                    <div className="order-store-text">
                                        <div className="order-store-name">{item.name}</div>
                                        <div className="order-store-address">{item.address}</div>
                                    </div>
                                    {parseInt(data.storeID) === parseInt(item.id) &&
                                        <div className="check">
                                            <CheckOutlined />
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="order-status-change">

                        <h2>Изменить статус заказа</h2>
                        <p>Нажмите на статус который хотите изменить</p>
                        <div className="order-status">
                            {list.map((item, index) =>
                                <div onClick={() => this.changeDeliveryStatus(item.key)} key={`${index}`} className={data.deliveryStatus === item.key ? `order-status-item order-status-item-active-${item.color}` : `order-status-item`} >
                                    {item.text}
                                </div>
                            )}
                        </div>


                        <h2>Изменить статус доставки</h2>
                        <p>Нажмите на статус который хотите изменить</p>
                        <div className="order-status">
                            {utils.DELIVERY_TYPE.map((item, index) =>
                                <div onClick={() => this.changeDeliveryType(item.key)} key={`${index}`} className={data.deliveryType === item.key ? `order-status-item order-status-item-active-${item.color}` : `order-status-item`} >
                                    {item.text}
                                </div>
                            )}
                        </div>

                        <h2>Изменить вид оплаты</h2>
                        <p>Нажмите на статус который хотите изменить</p>
                        <div className="order-status">
                            {utils.PAYMENT_TYPE.map((item, index) =>
                                <div onClick={() => this.changePaymentType(item.key)} key={`${index}`} className={data.paymentType === item.key ? `order-status-item order-status-item-active-${item.color}` : `order-status-item`} >
                                    {item.text}
                                </div>
                            )}
                        </div>

                        <h2>Изменить статус оплаты</h2>
                        <p>Нажмите на статус который хотите изменить</p>
                        <div className="order-status">
                            {utils.PAYMENT_STATUS.map((item, index) =>
                                <div onClick={() => this.changePaymentStatus(item.key)} key={`${index}`} className={data.paymentStatus === item.key ? `order-status-item order-status-item-active-${item.color}` : `order-status-item`} >
                                    {item.text}
                                </div>
                            )}
                        </div>

                    </div>

                </div>
            )
        }
    }



    render = () => {
        return (
            <div className="content">

                {this._top()}

                <div className="content-box">
                    {this._content()}
                    {this._change()}
                </div>

            </div>
        )
    }

}

export default withRouter(Order)