import React, { Component } from "react"


/*
    Page Managers
*/
class Managers extends Component {  

    render = () => {

        return (
            <div>
                Managers
            </div>
        )

    }

}


export default Managers