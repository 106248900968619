import axios from "axios"
import { env } from '../constants'


/* LIST */
export const list = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}shop/list`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Count */
export const count = () => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}shop/count`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* Remove */
export const remove = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}shop/remove`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Cities */
export const cities = () => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}shop/cities`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* CREATE */
export const create = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}shop/create`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* UPDATE */
export const update = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}shop/update`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* GET */
export const get = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}shop/get`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}


/* STOCK */
export const stock = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}shop/stock/get`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* STOCK COUNT */
export const countStock = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}shop/stock/count`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}