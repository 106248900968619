import axios from "axios"
import { env } from '../constants'



/* Login */
export const login = data => {
    const uri = `${env.startpoint}manager/login`
    return axios.post(uri, data).then(response => response).catch(error => error.response)
}

/* Register */
export const register = data => {
    const uri = `${env.startpoint}manager/register`
    return axios.post(uri, data).then(response => response).catch(error => error.response)
}