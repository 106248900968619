import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Helpers */
import { utils, withRouter } from '../../helpers'

/* REST API */
import { stock, countStock } from '../../api/Shop'


/*
    Edit Stock
*/
class EditStock extends Component {

    constructor(props) {
        super(props)

        const { params } = this.props

        this.state = {
            id: params.id,
            loading: true,
            data: [],
            page: 1,
            count: 0,
        }
    }


    componentDidMount = () => {
        this.load()
        this.count()
    }

    /* Load */
    load = (page = 1) => {

        const { id } = this.state

        this.setState({ page, loading: true })

        stock({ id, page }).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data, loading: false })
            }
        })

    }

    /* Count */
    count = () => {
        
        const { id } = this.state

        countStock({ id }).then(response => {
            if (response.status === 200) {
                this.setState({ count: response.data.total })
            }
        })
    }

    /* Draw Top */
    _top = () => {

        const { count } = this.state

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/store.png" alt="Store" />
                    <p>Запасы</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

            </div>
        )
    }


    /* Draw content */
    _content = () => {

        const { data, loading, page } = this.state

        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="content-element">
                    Здесь пока пусто
                </div>
            )
        }

        return (
            <div className="content-data-box">
                {data.map((item, index) =>
                    <div className="content-box-body" key={`${index}`}>
                        <div className="content-item number">{index + 1 + (page - 1) * 10}</div>
                        <div className="content-item">{utils.check(item.store)}</div>
                        <div className="content-item name">{utils.check(item.name)}</div>
                        <div className="content-item number"></div>
                        <div className="content-item name">{utils.check(item.amount)}</div>
                    </div>
                )}
            </div>
        )

    }



    render = () => {

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">

                    <div className="content-box-head">
                        <div className="content-head number">#</div>
                        <div className="content-head">Магазин</div>
                        <div className="content-head name">Продукт</div>
                        <div className="content-head number"></div>
                        <div className="content-head name">Остаток</div>
                    </div>

                    {this._content()}

                </div>

            </div>
        )
    }

}

export default withRouter(EditStock)